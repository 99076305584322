import React, { type ReactNode, Suspense, useEffect, useState } from 'react';
import { styled } from 'styled-components';

import { useI18n } from '@mirakl/i18n';
import {
    Box,
    Flex,
    Hyperlink,
    LayoutContainer,
    LayoutItem,
    Media,
    Page,
    PageLoader,
    PageTitle,
    Paragraph,
    Skeleton,
    Text,
    Title,
    useNavigate,
} from '@mirakl/roma';

import useOrganizationContext from '../../OrganizationContext';
import useSubscriptionApi, {
    Paywall,
    PaywallType,
} from '../useSubscriptionApi';

import PaywallCard from './components/paywallCard/PaywallCard';
import PaywallCardHeader from './components/paywallCardHeader/PaywallCardHeader';
import PaywallCardItem from './components/paywallCardItem/PaywallCardItem';
import PaywallCardSection from './components/paywallCardSection/PaywallCardSection';

const CenteredTitle = styled(Title)`
    text-align: center;
    text-wrap: balance;
`;

const CenteredText = styled(Paragraph)`
    text-align: center;
    text-wrap: balance;
`;

const CardContainer = styled(Flex).attrs({
    gap: 4,
    padding: 2,
})`
    scroll-snap-type: x mandatory;
    overflow-y: auto;
`;

const CardWrapper = ({ children }: { children: ReactNode }) => (
    <Flex justifyContent="center">
        <CardContainer
            paddingBottom={5}
            paddingHorizontal={{ sm: 5, default: 6 }}
        >
            {children}
        </CardContainer>
    </Flex>
);

interface CardsProps {
    onPlanSelected: (planId: PaywallType) => void;
    plans: Paywall;
}

const Cards = ({ onPlanSelected, plans }: CardsProps) => {
    const { formatMessage } = useI18n();

    return (
        <CardWrapper>
            <PaywallCard>
                <PaywallCardHeader
                    currentPlan={plans.start.currentPlan}
                    heading={
                        <Text color="grey-500" variant="small-400">
                            {formatMessage({
                                id: 'organization.paywall.start.heading',
                            })}
                        </Text>
                    }
                    plan="start"
                    price={formatMessage({
                        id: 'organization.paywall.card.price.free',
                    })}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="basic-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem disabled type="catalog-transformer" />
                    <PaywallCardItem
                        disabled
                        type="multi-channel-customer-care"
                    />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        subtitleVar={plans.start.addOns.channels.displayPrice}
                        titleVar="10"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.start.addOns.products.displayPrice}
                        titleVar="50"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.start.addOns.gmv.displayPrice}
                        titleVar="$25"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-limited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem disabled type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    currentPlan={plans.growth.currentPlan}
                    plan="growth"
                    price={plans.growth.displayPrice + '/mo'}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="advanced-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem
                        badge={{ label: 'AI', iconName: 'sparkle' }}
                        type="catalog-transformer"
                    />
                    <PaywallCardItem type="multi-channel-customer-care" />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        subtitleVar={plans.growth.addOns.channels.displayPrice}
                        titleVar="10"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.growth.addOns.products.displayPrice}
                        titleVar="50"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.growth.addOns.gmv.displayPrice}
                        titleVar="$25"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-unlimited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem disabled type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    currentPlan={plans.pro.currentPlan}
                    plan="pro"
                    price={plans.pro.displayPrice + '/mo'}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="advanced-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem
                        badge={{ label: 'AI', iconName: 'sparkle' }}
                        type="catalog-transformer"
                    />
                    <PaywallCardItem type="multi-channel-customer-care" />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        subtitleVar={plans.pro.addOns.channels.displayPrice}
                        titleVar="30"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.pro.addOns.products.displayPrice}
                        titleVar="150"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.pro.addOns.gmv.displayPrice}
                        titleVar="$150"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-unlimited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    currentPlan={plans.business.currentPlan}
                    plan="business"
                    price={plans.business.displayPrice + '/mo'}
                    onPlanSelected={onPlanSelected}
                />
                <PaywallCardSection type="networking">
                    <PaywallCardItem type="networking" />
                    <PaywallCardItem type="fast-store-creation" />
                </PaywallCardSection>
                <PaywallCardSection type="channel-manager">
                    <PaywallCardItem
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.connectors.title.tooltip',
                        })}
                        type="advanced-connectors"
                    />
                    <PaywallCardItem type="inventory-management" />
                    <PaywallCardItem type="order-management" />
                    <PaywallCardItem
                        badge={{ label: 'AI', iconName: 'sparkle' }}
                        type="catalog-transformer"
                    />
                    <PaywallCardItem type="multi-channel-customer-care" />
                </PaywallCardSection>
                <PaywallCardSection type="base-limits-overages">
                    <PaywallCardItem
                        subtitleVar={
                            plans.business.addOns.channels.displayPrice
                        }
                        titleVar="50"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.channels.tooltip',
                        })}
                        type="channels"
                    />
                    <PaywallCardItem
                        subtitleVar={
                            plans.business.addOns.products.displayPrice
                        }
                        titleVar="250"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.products.tooltip',
                        })}
                        type="products"
                    />
                    <PaywallCardItem
                        subtitleVar={plans.business.addOns.gmv.displayPrice}
                        titleVar="$400"
                        tooltipText={formatMessage({
                            id: 'organization.paywall.card.item.gmv.tooltip',
                        })}
                        type="gmv"
                    />
                    <PaywallCardItem type="helpdesk-unlimited" />
                </PaywallCardSection>
                <PaywallCardSection type="services">
                    <PaywallCardItem type="professional-services" />
                </PaywallCardSection>
            </PaywallCard>
            <PaywallCard>
                <PaywallCardHeader
                    currentPlan={false}
                    plan="enterprise"
                    price={formatMessage({
                        id: 'organization.paywall.card.price.custom',
                    })}
                    onPlanSelected={onPlanSelected}
                />
                <Flex alignItems="center" flexDirection="column">
                    <Title tag="h3">
                        {formatMessage({
                            id: 'organization.paywall.enterprise.content.title',
                        })}
                    </Title>
                    <CenteredText>
                        {formatMessage({
                            id: 'organization.paywall.enterprise.content.subtitle',
                        })}
                    </CenteredText>
                    <Media name="work-team" type="illustration" />
                </Flex>
            </PaywallCard>
        </CardWrapper>
    );
};

const extractCallback = (url: string): string => {
    const urlParts = url.split('callback=');
    if (urlParts[1] === undefined) {
        throw new Error('URL param callback not found.');
    }
    return urlParts[1];
};

const PaywallPage = () => {
    const { formatMessage } = useI18n();
    const [plans, setPlans] = useState<Paywall>();
    const [isLoading, setIsLoading] = useState(true);
    const { uuid } = useOrganizationContext();
    const { getPlans, subscribe } = useSubscriptionApi();
    const navigate = useNavigate();
    const callback = extractCallback(window.location.href);
    useEffect(() => {
        getPlans(uuid).then((value) => {
            setPlans(value);
            setIsLoading(false);
        });
    }, [uuid, getPlans]);

    return isLoading || plans === undefined ? (
        <PageLoader fullPage />
    ) : (
        <Page>
            <PageTitle title="" />
            {/*we do not use PageLayout to allow a horizontal scroll for the cards*/}
            <main>
                <LayoutContainer>
                    <LayoutItem>
                        <Flex
                            alignItems="center"
                            flexDirection="column"
                            paddingHorizontal={6}
                        >
                            <CenteredTitle tag="h1">
                                {formatMessage(
                                    {
                                        id: 'organization.paywall.mainTitle',
                                    },
                                    { br: <br /> }
                                )}
                            </CenteredTitle>
                        </Flex>

                        <Suspense
                            fallback={
                                <Skeleton
                                    a11yLabel={formatMessage({
                                        id: 'organization.loading',
                                    })}
                                >
                                    <CardWrapper>
                                        {Array.from(
                                            { length: 5 },
                                            (_, index) => (
                                                <Box flexShrink={0} key={index}>
                                                    <Skeleton.Item
                                                        height="1100px"
                                                        width="270px"
                                                    />
                                                </Box>
                                            )
                                        )}
                                    </CardWrapper>
                                </Skeleton>
                            }
                        >
                            <Cards
                                plans={plans}
                                onPlanSelected={(planType: PaywallType) => {
                                    setIsLoading(true);
                                    subscribe(
                                        uuid,
                                        plans[planType].id,
                                        callback
                                    ).then((response) => {
                                        navigate({
                                            href: response.headers.location,
                                        });
                                    });
                                }}
                            />
                        </Suspense>
                        <Box paddingHorizontal={{ sm: 5, default: 6 }}>
                            <CenteredText>
                                {formatMessage(
                                    {
                                        id: 'organization.paywall.subtext',
                                    },
                                    {
                                        a: (chunk: string) => (
                                            <Hyperlink
                                                href="https://help.mirakl.net/bundle/sellers/page/topics/Mirakl/account/manage_subscriptions_mirakl_account.htm"
                                                variant="default-500"
                                            >
                                                {chunk}
                                            </Hyperlink>
                                        ),
                                    }
                                )}
                            </CenteredText>
                        </Box>
                    </LayoutItem>
                </LayoutContainer>
            </main>
        </Page>
    );
};

export default PaywallPage;
