import { useCallback } from 'react';

import { DatatableSeekPaginateDataType } from '@mirakl/roma';

import useAuthenticatedFetch from '../../../../fetch/useAuthenticatedFetch';
import { OperatorAccountUserType } from '../components/OperatorAccountUserListingDatatable';
import { OperatorAccountUserQueryParamType } from '../operatorAccountUserQueryParamHelper';

export type OperatorUserType = {
    dateCreated: string;
    id: string;
    lastLogin?: string;
    profile: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
    roles: string[];
};

type UserRolesPayload = {
    roles: string[];
};

export const useOperatorAccountUserApiClient = (id: string) => {
    const { apiGet } = useAuthenticatedFetch();

    const fetchUsers: (
        queryParams: OperatorAccountUserQueryParamType
    ) => Promise<DatatableSeekPaginateDataType<OperatorAccountUserType>> =
        useCallback(
            (queryParams) =>
                apiGet<DatatableSeekPaginateDataType<OperatorUserType>>(
                    `/private/operators/${id}/company/users`,
                    {
                        params: queryParams,
                    }
                ).then(({ data }) => data),
            [apiGet, id]
        );

    const getUserRoles: () => Promise<string[]> = useCallback(() => {
        return apiGet<UserRolesPayload>(
            `/private/operators/${id}/company/users/roles`
        ).then(({ data }) => data.roles);
    }, [apiGet, id]);

    return { fetchUsers, getUserRoles };
};
