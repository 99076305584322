import { ReactNode, useMemo } from 'react';

import { useI18n } from '@mirakl/i18n';
import { GlobalLayout } from '@mirakl/roma';

import useLoginContext from '../../config/login/LoginProvider';
import useOperatorAccountContext from '../../operators/OperatorAccountContext';
import { GlobalLayoutSidebarType } from '../../types/romaTypes';

import { HELP_PAGE_URL } from './AccountGlobalLayout';

type OperatorUserGlobalLayoutProps = {
    children: ReactNode;
};

const OperatorUserGlobalLayout = ({
    children,
}: OperatorUserGlobalLayoutProps) => {
    const { formatMessage } = useI18n();

    const { email } = useLoginContext();
    const { id, isClientAccount, logoUrl, name } = useOperatorAccountContext();

    const usersMenuItems = useMemo(() => {
        const subItems = [
            {
                id: 'menu-item-user-listing',
                label: formatMessage({
                    id: 'operator.accounts.users.listing.title',
                }),
                to: `/operators/${id}/users`,
            },
        ];
        if (isClientAccount) {
            subItems.push({
                id: 'menu-item-users-ticketing-licence',
                label: formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.title',
                }),
                to: `/operators/${id}/users/ticketing/licences`,
            });
        }
        return subItems;
    }, [id, isClientAccount, formatMessage]);

    const menuItems = useMemo(
        (): GlobalLayoutSidebarType => [
            {
                id: 'menu-item-company-information',
                label: formatMessage({
                    id: 'operator.accounts.detail.company.information',
                }),
                iconName: 'store',
                to: `/operators/${id}`,
            },
            {
                id: 'menu-item-users',
                label: formatMessage({ id: 'operator.accounts.users.title' }),
                iconName: 'person',
                subItems: usersMenuItems,
            },
        ],
        [formatMessage, id, usersMenuItems]
    );

    return (
        <GlobalLayout
            sidebar={{
                footerItems: [
                    {
                        id: 'menu-item-help-center',
                        label: formatMessage({ id: 'help.center' }),
                        iconName: 'support',
                        href: HELP_PAGE_URL,
                        openInNewTab: true,
                    },
                ],
                menuItems: menuItems,
                media: logoUrl
                    ? {
                          url: logoUrl,
                          alt: formatMessage({
                              id: 'operator.accounts.layout.logo.alt',
                          }),
                          type: 'image',
                      }
                    : undefined,
                title: name,
                subtitle: email,
            }}
            topBar={{
                buttons: undefined, // Nothing in the topBar
            }}
        >
            {children}
        </GlobalLayout>
    );
};

export default OperatorUserGlobalLayout;
