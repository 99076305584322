import SupportOperatorAccountDetailsLayout from '../../../detail/support/SupportOperatorAccountDetailsLayout';

import TicketingLicenceUsersPanel from './components/TicketingLicenceUsersPanel';

const SupportTicketingLicenceUsersPage = () => {
    return (
        <SupportOperatorAccountDetailsLayout size="sm">
            <TicketingLicenceUsersPanel />
        </SupportOperatorAccountDetailsLayout>
    );
};

export default SupportTicketingLicenceUsersPage;
