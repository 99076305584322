import { DateTime } from 'luxon';
import React from 'react';

import { useI18n } from '@mirakl/i18n';
import { Flex, Text } from '@mirakl/roma';

import { CurrentPlanDetail } from './SubscriptionPage';

type SubscriptionDetailProps = {
    currentPlanDetail: CurrentPlanDetail;
    isOrganizationAdmin: boolean;
};

function SubscriptionDetailsSection({
    currentPlanDetail,
}: SubscriptionDetailProps) {
    const { formatMessage } = useI18n();
    const billingDay = DateTime.fromISO(currentPlanDetail.nextBillingDate).day;

    return (
        <>
            <Flex flexDirection="column" marginTop={1}>
                <Text variant="default-500">
                    {formatMessage({
                        id: 'organization.subscription.details.type',
                    })}
                </Text>
                <Text variant="default-400">
                    {formatMessage({
                        id: `organization.plan.type.${currentPlanDetail.type.toLowerCase()}`,
                    })}
                </Text>
            </Flex>
            <Flex flexDirection="column" marginTop={2}>
                <Text variant="default-500">
                    {formatMessage({
                        id: 'organization.subscription.details.billing.date',
                    })}
                </Text>
                <Text variant="default-400">
                    {formatMessage(
                        {
                            id: 'organization.subscription.details.billing.date.scheduled',
                        },
                        { 0: billingDay }
                    )}
                </Text>
            </Flex>
        </>
    );
}

export default SubscriptionDetailsSection;
