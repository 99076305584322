import { useEffect, useMemo, useState } from 'react';

import { useI18n } from '@mirakl/i18n';
import {
    Datatable,
    DatatableRowDataType,
    createHeadingsHelper,
} from '@mirakl/roma';

import { UserProfileInfoCellContent } from '../../../../../common/datatable/UserProfileInfoCellContent';

type TicketingLicenceUserRowType = DatatableRowDataType & {
    user: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
};

export type TicketingLicenceUsersSortColumnType = 'user';
export type TicketingLicenceUsersSortDirectionType = 'ASC' | 'DESC';
export type TicketingLicenceUsersSortType = {
    column: TicketingLicenceUsersSortColumnType;
    direction: TicketingLicenceUsersSortDirectionType;
};

const headingsHelper = createHeadingsHelper<TicketingLicenceUserRowType>();

const applySortByUser = (
    licences: TicketingLicenceUserRowType[],
    direction: TicketingLicenceUsersSortDirectionType
) => {
    const sorted = Array.from(licences).sort((a, b) =>
        a.user.email.localeCompare(b.user.email)
    );
    return direction === 'ASC' ? sorted : sorted.reverse();
};

const applySort = (
    licences: TicketingLicenceUserRowType[],
    sort?: TicketingLicenceUsersSortType
) => {
    if (!sort) {
        return licences;
    }
    if (sort.column === 'user') {
        return applySortByUser(licences, sort.direction);
    }
    return licences;
};

const applySearch = (
    licences: TicketingLicenceUserRowType[],
    search?: string
) => {
    if (!search || search === '') {
        return licences;
    }

    return licences.filter(({ user }) =>
        user.email.toLowerCase().includes(search.toLowerCase())
    );
};

const TicketingLicenceUsersDatatable = ({
    loading,
    search,
    sort,
    users,
}: {
    loading: 'loading' | 'done' | 'error' | 'precondition-failed';
    search?: string;
    sort?: TicketingLicenceUsersSortType;
    users: TicketingLicenceUserRowType[];
}) => {
    const { formatMessage } = useI18n();

    const [filteredLicencedUsers, setFilteredLicencedUsers] =
        useState<TicketingLicenceUserRowType[]>(users);

    useEffect(() => {
        let filtered = users;
        filtered = applySearch(filtered, search);
        filtered = applySort(filtered, sort);
        setFilteredLicencedUsers(filtered);
    }, [users, search, sort]);

    const headings = useMemo(() => {
        return [
            headingsHelper.accessor('user', {
                label: formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.listing.header.name',
                }),
                render: (user) => (
                    <UserProfileInfoCellContent profileInfo={user} />
                ),
                initialSortDirection: 'ASC',
                sortable: true,
            }),
        ];
    }, [formatMessage]);

    return (
        <Datatable
            data={filteredLicencedUsers}
            emptyState={
                loading === 'precondition-failed'
                    ? {
                          title: formatMessage({
                              id: 'operator.accounts.ticketing.licence.users.listing.preconditon.failed.title',
                          }),
                          subtitle: formatMessage({
                              id: 'operator.accounts.ticketing.licence.users.listing.preconditon.failed.subtitle',
                          }),
                      }
                    : {
                          title: formatMessage({
                              id: 'operator.accounts.ticketing.licence.users.listing.empty.title',
                          }),
                      }
            }
            headings={headings}
            id="ticketing-licence-users-listing-datatable"
            loading={loading === 'precondition-failed' ? 'done' : loading}
            resultNumberMessage={formatMessage(
                { id: 'count.result' },
                { 0: filteredLicencedUsers.length }
            )}
            toolbar={{
                searchBar: {
                    placeholder: formatMessage({
                        id: 'operator.accounts.ticketing.licence.users.listing.search.placeholder',
                    }),
                    id: 'search',
                },
            }}
        />
    );
};

export default TicketingLicenceUsersDatatable;
