import React from 'react';

import { useI18n } from '@mirakl/i18n';
import { EmptyState, Hyperlink, Panel, Text } from '@mirakl/roma';

function NoSubscription() {
    const { formatMessage } = useI18n();

    return (
        <>
            <Panel>
                <Panel.Content>
                    <EmptyState
                        illustrationName="payment-credit"
                        illustrationSize="medium"
                        subtitle={
                            <Text>
                                {formatMessage({
                                    id: 'organization.subscription.price.empty.state.subtitle',
                                })}{' '}
                                <Hyperlink
                                    href="https://help.mirakl.net/bundle/sellers/page/topics/Mirakl/account/manage_subscriptions_mirakl_account.htm"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {formatMessage({
                                        id: 'organization.subscription.price.empty.state.subtitle.link',
                                    })}
                                </Hyperlink>
                            </Text>
                        }
                    />
                </Panel.Content>
            </Panel>
        </>
    );
}

export default NoSubscription;
