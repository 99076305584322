import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import {
    DatatableProvider,
    DatatableRowDataType,
    createQueryParamsHelper,
} from '@mirakl/roma';

import useOperatorAccountContext from '../../../../OperatorAccountContext';
import useTicketingLicenceApi from '../api/useTicketingLicenceApi';

import TicketingLicenceUsersDatatable, {
    TicketingLicenceUsersSortColumnType,
    TicketingLicenceUsersSortDirectionType,
    TicketingLicenceUsersSortType,
} from './TicketingLicenceUsersDatatable';

type TicketingLicenceUserRowType = DatatableRowDataType & {
    user: {
        email: string;
        firstName?: string;
        lastName?: string;
    };
};

const ticketingLicenceUsersListingQueryParamsHelper = createQueryParamsHelper()
    .withSearch('search')
    .withSort('sort');

const TicketingLicenceUsersListing = () => {
    const { id: operatorAccountId } = useOperatorAccountContext();
    const { QueryParamsProvider } =
        ticketingLicenceUsersListingQueryParamsHelper;
    const { fetchTicketingLicenceUsers } = useTicketingLicenceApi();

    const [licencedUsers, setLicencedUsers] = useState<
        TicketingLicenceUserRowType[]
    >([]);
    const [licencedUsersLoading, setLicencedUsersLoading] = useState<
        'loading' | 'done' | 'error' | 'precondition-failed'
    >('loading');
    const [licensedUsersSearch, setLicensedUsersSearch] = useState<string>();
    const [licensedUsersSort, setLicensedUsersSort] =
        useState<TicketingLicenceUsersSortType>();

    const handleError = useCallback((error: Error) => {
        if (error instanceof AxiosError) {
            if (error.response && error.response.status === 412) {
                setLicencedUsersLoading('precondition-failed');
                return;
            }
        }
        setLicencedUsersLoading('error');
    }, []);

    useEffect(() => {
        fetchTicketingLicenceUsers(operatorAccountId)
            .then((data) => {
                setLicencedUsers(
                    data.users.map((user) => ({
                        id: user.email,
                        user: user,
                    }))
                );
                setLicencedUsersLoading('done');
            })
            .catch(handleError);
    }, [fetchTicketingLicenceUsers, handleError, operatorAccountId]);

    return (
        <QueryParamsProvider
            onQueryParamsChange={({ search, sort }) => {
                setLicensedUsersSearch(search?.value);

                const [column, direction] = sort?.value?.split(',') as [
                    TicketingLicenceUsersSortColumnType,
                    TicketingLicenceUsersSortDirectionType,
                ];
                setLicensedUsersSort({
                    column,
                    direction,
                });
            }}
        >
            <DatatableProvider>
                <TicketingLicenceUsersDatatable
                    loading={licencedUsersLoading}
                    search={licensedUsersSearch}
                    sort={licensedUsersSort}
                    users={licencedUsers}
                />
            </DatatableProvider>
        </QueryParamsProvider>
    );
};

export default TicketingLicenceUsersListing;
