import { useI18n } from '@mirakl/i18n';
import { Panel, Paragraph } from '@mirakl/roma';

import TicketingLicenceUsersListing from './TicketingLicenceUsersListing';

const TicketingLicenceUsersPanel = () => {
    const { formatMessage } = useI18n();
    return (
        <Panel>
            <Panel.Header
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.listing.title',
                })}
            />
            <Panel.Content>
                <Paragraph>
                    {formatMessage({
                        id: 'operator.accounts.ticketing.licence.users.listing.hint',
                    })}
                </Paragraph>
            </Panel.Content>
            <Panel.Content fullWidth>
                <TicketingLicenceUsersListing />
            </Panel.Content>
        </Panel>
    );
};

export default TicketingLicenceUsersPanel;
