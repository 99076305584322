import { ReactNode, useCallback } from 'react';

import { useI18n } from '@mirakl/i18n';
import { GlobalLayout } from '@mirakl/roma';

import useLoginContext from '../../config/login/LoginProvider';
import useOperatorAccountsUrls from '../../operators/useOperatorAccountsUrls';
import { GlobalLayoutSidebarType } from '../../types/romaTypes';

import { HELP_PAGE_URL } from './AccountGlobalLayout';

function MiraklSupportGlobalLayout({
    children,
}: {
    children: ReactNode | ReactNode[];
}) {
    const { email, miraklSupport } = useLoginContext();
    const { formatMessage } = useI18n();
    const { defaultListingUrl } = useOperatorAccountsUrls();

    const getMiraklSupportSidebarMenuItems = useCallback(() => {
        const menuItems: GlobalLayoutSidebarType = [];
        if (miraklSupport.isSellerSupportUser) {
            menuItems.push({
                id: 'menu-item-organizations',
                label: formatMessage({ id: 'organizations' }),
                iconName: 'store',
                to: '/organizations',
            });
        }
        if (miraklSupport.isSellerSupportUser) {
            menuItems.push({
                id: 'menu-item-operator-accounts',
                label: formatMessage({ id: 'operator.accounts' }),
                iconName: 'work',
                to: defaultListingUrl,
            });
        }
        // TODO Waiting for ACCOUNT-36
        // {
        //     label: formatMessage({ id: 'history' }),
        //     iconName: 'history',
        //     to: '/organizations/history',
        // },
        return menuItems;
    }, [defaultListingUrl, formatMessage, miraklSupport]);

    return (
        <GlobalLayout
            sidebar={{
                footerItems: [
                    {
                        id: 'menu-item-help-center',
                        label: formatMessage({ id: 'help.center' }),
                        iconName: 'support',
                        href: HELP_PAGE_URL,
                        openInNewTab: true,
                    },
                ],
                media: {
                    url: '/static/images/mirakl-logo.png',
                    alt: 'Mirakl',
                    type: 'image',
                },
                menuItems: getMiraklSupportSidebarMenuItems(),
                subtitle: email,
                title: 'Mirakl',
            }}
            topBar={{
                buttons: undefined, // Nothing in the topBar
            }}
        >
            {children}
        </GlobalLayout>
    );
}

export default MiraklSupportGlobalLayout;
