import { Route } from 'react-router-dom-v5-compat';

import { Routes } from '@mirakl/analytics';

import { OperatorAccountProvider } from './OperatorAccountContext';
import OperatorSupportOnlyRoute from './OperatorSupportOnlyRoute';
import OperatorCompanyInformationPage from './detail/operator/OperatorCompanyInformationPage';
import SupportCompanyInformationPage from './detail/support/SupportCompanyInformationPage';
import OperatorAccountListingPage from './listing/OperatorAccountListingPage';
import OperatorAccountUserListingPage from './users/listing/OperatorAccountUserListingPage';
import SupportOperatorAccountUserListingPage from './users/listing/SupportOperatorAccountUserListingPage';
import OperatorAccountTicketingLicenceUsersPage from './users/ticketing/licence/OperatorAccountTicketingLicenceUsersPage';
import SupportTicketingLicenceUsersPage from './users/ticketing/licence/SupportTicketingLicenceUsersPage';

const OperatorAccountPages = () => (
    <Routes>
        <Route element={<OperatorSupportOnlyRoute />}>
            <Route element={<OperatorAccountListingPage />} index />
        </Route>
        <Route
            element={
                <OperatorAccountProvider>
                    <Routes>
                        <Route
                            element={<OperatorAccountUserListingPage />}
                            path="/users"
                        />
                        <Route
                            element={
                                <OperatorAccountTicketingLicenceUsersPage />
                            }
                            path="/users/ticketing/licences"
                        />
                        <Route
                            element={<OperatorCompanyInformationPage />}
                            index
                        />

                        <Route element={<OperatorSupportOnlyRoute />}>
                            <Route
                                element={<SupportCompanyInformationPage />}
                                path="/company"
                            />
                            <Route
                                element={
                                    <SupportOperatorAccountUserListingPage />
                                }
                                path="/company/users"
                            />
                            <Route
                                element={<SupportTicketingLicenceUsersPage />}
                                path="/company/users/ticketing/licences"
                            />
                        </Route>
                    </Routes>
                </OperatorAccountProvider>
            }
            path="/:id/*"
        />
    </Routes>
);

export default OperatorAccountPages;
