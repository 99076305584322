import { useI18n } from '@mirakl/i18n';
import {
    LayoutContainer,
    LayoutItem,
    PageLayout,
    PageTitle,
} from '@mirakl/roma';

import OperatorUserGlobalLayout from '../../../../common/layout/OperatorUserGlobalLayout';

import TicketingLicenceUsersPanel from './components/TicketingLicenceUsersPanel';

const OperatorAccountTicketingLicenceUsersPage = () => {
    const { formatMessage } = useI18n();
    return (
        <OperatorUserGlobalLayout>
            <PageTitle
                title={formatMessage({
                    id: 'operator.accounts.ticketing.licence.users.title',
                })}
            />
            <PageLayout size="sm">
                <LayoutContainer>
                    <LayoutItem>
                        <TicketingLicenceUsersPanel />
                    </LayoutItem>
                </LayoutContainer>
            </PageLayout>
        </OperatorUserGlobalLayout>
    );
};

export default OperatorAccountTicketingLicenceUsersPage;
