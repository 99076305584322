import { useCallback } from 'react';

import useAuthenticatedFetch from '../../../../../fetch/useAuthenticatedFetch';

type TicketingLicenceUsersPayloadType = {
    users: {
        email: string;
        firstName?: string;
        lastName?: string;
    }[];
};

const useTicketingLicenceApi = () => {
    const { apiGet } = useAuthenticatedFetch();

    const fetchTicketingLicenceUsers: (
        operatorAccountId: string
    ) => Promise<TicketingLicenceUsersPayloadType> = useCallback(
        (operatorAccountId) =>
            apiGet<TicketingLicenceUsersPayloadType>(
                `/private/operators/${operatorAccountId}/ticketing/licences/users`
            ).then(({ data }) => data),
        [apiGet]
    );

    return {
        fetchTicketingLicenceUsers,
    };
};

export default useTicketingLicenceApi;
